<template>
  <div id="settings">
    <a-row type="flex" :gutter="[24,24]">

      <a-col :span="24" :lg="6">

        <!-- Page Anchors -->
        <a-affix :offset-top=" navbarFixed ? 100 : 10 ">
          <a-card :bordered="false" class="header-solid mb-24">
            <a-anchor :targetOffset=" navbarFixed ? 100 : 10 " :affix="false">
              <a-anchor-link href="#notifications">
                <div slot="title" class="ant-list-item-meta">
                  <a-icon type="notification" theme="filled" class="text-gray-6 text-lg" />
                  <h4 class="ant-list-item-meta-title">
                    <span class="font-regular">消息通知</span>
                  </h4>
                </div>
              </a-anchor-link>
              <a-anchor-link href="#change-password">
                <div slot="title" class="ant-list-item-meta">
                  <a-icon type="unlock" theme="filled" class="text-gray-6 text-lg" />
                  <h4 class="ant-list-item-meta-title">
                    <span class="font-regular">修改密码</span>
                  </h4>
                </div>
              </a-anchor-link>
              <a-anchor-link href="#delete-task">
                <div slot="title" class="ant-list-item-meta">
                  <a-icon type="redo" class="text-gray-6 text-lg" />
                  <h4 class="ant-list-item-meta-title">
                    <span class="font-regular">重置收益</span>
                  </h4>
                </div>
              </a-anchor-link>
              <a-anchor-link href="#delete-account">
                <div slot="title" class="ant-list-item-meta">
                  <a-icon type="delete" theme="filled" class="text-gray-6 text-lg" />
                  <h4 class="ant-list-item-meta-title">
                    <span class="font-regular">删除账户</span>
                  </h4>
                </div>
              </a-anchor-link>
            </a-anchor>
          </a-card>
        </a-affix>
        <!-- / Page Anchors -->

      </a-col>
      <a-col :span="24" :lg="18">

        <!-- notifications card -->
        <a-card :bordered="false" id="notifications" class="header-solid mb-24">
          <template #title>
            <h5 class="font-semibold">消息通知</h5>
            <p class="font-regular">设置消息通知渠道</p>
          </template>
          <a-form
              :hideRequiredMark="true"
          >
            <a-row type="flex" align="middle">
              <a-col>
                <a-avatar :size="48" src="images/logos/wechat.svg" />
              </a-col>
              <a-col class="pl-15">
                <h5 class="mb-0">微信服务号</h5>
                <a class="text-dark" @click="toggleShowLess('wechat')">{{ showLess_wechat ? '收起详细步骤' : '展开详细步骤'}}<a-icon :type="showLess_wechat ? 'up' : 'down'" class="ml-5" /></a>
              </a-col>
              <a-col :span="24" :md="12" class="ml-auto" style="display: flex; align-items: center; justify-content: flex-end">
                <span class="mr-15">{{ wechat ? '开启' : '关闭' }}</span>
                <a-switch default-checked v-model="wechat" @change="onChange('wechat')"/>
              </a-col>
            </a-row>
            <a-row type="flex" v-if="showLess_wechat" class="mt-15" style="flex-wrap: nowrap;">
              <a-col style="width: 48px"></a-col>
              <a-col style="padding-left: 15px;width: 100%;">
                <p>
                  关注微信公众号『ApePush』，输入授权码，完成账号关联。
                  <br />
                  为避免风控，只做简易消息推送。希望获取详细消息推送，请使用QQ邮箱方式。
                </p>

                <a-card :bordered="false" class="bg-gray-3 shadow-0" :bodyStyle="{padding: '8px'}">
                  <a-row type="flex" align="middle">
                    <a-col><p class="font-semibold mb-0 ml-10">授权码</p></a-col>
                    <a-col class="ml-auto">
                      <a-input v-model="wechat_code" size="small" class="font-regular text-sm text-dark" style="width: 200px;"/>
                      <a-button size="small" type="primary" class="ml-10 px-25 font-bold" @click="generateWechatCode">生成授权码</a-button>
                    </a-col>
                  </a-row>
                </a-card>
<!--                <a-card :bordered="false" class="bg-gray-3 shadow-0 mb-24" :bodyStyle="{padding: '8px'}">-->
<!--                  <a-row type="flex" align="middle">-->
<!--                    <a-col><p class="font-semibold mb-0 ml-10">授权码</p></a-col>-->
<!--                    <a-col class="ml-auto">-->
<!--                      <a-input value="1172913" size="small" class="font-regular text-sm text-dark" style="width: 100px;" />-->
<!--                    </a-col>-->
<!--                  </a-row>-->
<!--                </a-card>-->

<!--                <a-card :bordered="false" class="bg-gray-3 shadow-0" :bodyStyle="{padding: '8px'}">-->
<!--                  <a-row type="flex" align="middle">-->
<!--                    <a-col><p class="font-semibold mb-0 ml-10">Verification Code</p></a-col>-->
<!--                    <a-col class="ml-auto">-->
<!--                      <strong class="font-semibold">hello@creative-tim.com</strong>-->
<!--                      <a-button size="small" type="danger" class="ml-10 px-25 font-bold">DELETE</a-button>-->
<!--                    </a-col>-->
<!--                  </a-row>-->
<!--                </a-card>-->
              </a-col>
            </a-row>
            <hr class="gradient-line">
            <a-row type="flex" align="middle">
              <a-col>
                <a-avatar :size="48" src="images/logos/QQmail.svg" />
              </a-col>
              <a-col class="pl-15">
                <h5 class="mb-0">QQ邮箱</h5>
                <a class="text-dark" @click="toggleShowLess('qq_mail')">{{ showLess_qq_mail ? '收起详细步骤' : '展开详细步骤'}}<a-icon :type="showLess_qq_mail ? 'up' : 'down'" class="ml-5" /></a>
              </a-col>
              <a-col :span="24" :md="12" class="ml-auto" style="display: flex; align-items: center; justify-content: flex-end">
                <span class="mr-15">{{ qq_mail ? '开启' : '关闭' }}</span>
                <a-switch default-checked @change="onChange('qq_mail')" v-model="qq_mail" />
              </a-col>
            </a-row>
            <a-row type="flex" v-if="showLess_qq_mail" class="mt-15" style="flex-wrap: nowrap;">
              <a-col style="width: 48px"></a-col>
              <a-col style="padding-left: 15px;width: 100%;">
                <p>
                  使用自己的QQ邮箱给自己发送通知邮件，收信成功率100%！
                  <br />
                  参考教程：<a href="https://www.jijyun.cn/help/detail/914" target="_blank">如何开启QQ邮箱SMTP服务？</a>
                </p>

                <a-card :bordered="false" class="bg-gray-3 shadow-0 mb-24" :bodyStyle="{padding: '8px'}">
                  <a-row type="flex" align="middle" style="margin-top: 10px">
                    <a-col><p class="font-semibold mb-0 ml-10">发信QQ邮箱</p></a-col>
                    <a-col class="ml-auto">
                      <a-input v-model="qq" size="small" class="font-regular text-sm text-dark" style="width: 135px;margin-right: 5px" />@qq.com
                    </a-col>
                  </a-row>
                  <a-row type="flex" align="middle" style="margin-top: 10px">
                    <a-col><p class="font-semibold mb-0 ml-10">邮箱授权码</p></a-col>
                    <a-col class="ml-auto">
                      <a-input v-model="qq_password" size="small" class="font-regular text-sm text-dark" style="width: 200px;" />
                    </a-col>
                  </a-row>
                  <a-row type="flex" align="middle" style="margin-top: 10px">
                    <a-col class="ml-auto">
                      <a-button size="small" type="primary" @click="submitQQmail()" class="ml-10 px-25 font-bold">确认</a-button>
                    </a-col>
                  </a-row>
                </a-card>
              </a-col>
            </a-row>
            <hr class="gradient-line">
            <a-row type="flex" align="middle">
              <a-col>
                <a-avatar :size="48" src="images/logos/telegram.svg" />
              </a-col>
              <a-col class="pl-15">
                <h5 class="mb-0">Telegram机器人</h5>
                <a class="text-dark" @click="toggleShowLess('telegram_bot')">{{ showLess_telegram_bot ? '收起详细步骤' : '展开详细步骤'}}<a-icon :type="showLess_telegram_bot ? 'up' : 'down'" class="ml-5" /></a>
              </a-col>
              <a-col :span="24" :md="12" class="ml-auto" style="display: flex; align-items: center; justify-content: flex-end">
                <span class="mr-15">{{ telegram_bot ? '开启' : '开发中' }}</span>
                <a-switch default-checked v-model="telegram_bot" disabled/>
              </a-col>
            </a-row>
            <a-row type="flex" v-if="showLess_telegram_bot" class="mt-15" style="flex-wrap: nowrap;">
              <a-col style="width: 48px"></a-col>
              <a-col style="padding-left: 15px;width: 100%;">
                <p style="margin-bottom: 0px;">
                  搜索或点击：<a href="https://t.me/copyapes_bot" target="_blank"></a>
                  <br />
                  添加Telegram机器人，并登录跟单猿账号。
                </p>
              </a-col>
            </a-row>
          </a-form>
        </a-card>
        <!-- / notifications card -->

        <!-- Change Password card -->
        <a-card :bordered="false" id="change-password" class="header-solid mb-24">
          <template #title>
            <h5 class="mb-0 font-semibold">修改密码</h5>
          </template>
          <a-form
              :hideRequiredMark="true"
          >
            <a-row :gutter="[24]">
              <a-col :span="24">
                <a-form-item class="mb-10" label="当前密码" :colon="false">
                  <a-input-password v-model="password" placeholder="请输入当前密码" />
                </a-form-item>
              </a-col>
              <a-col :span="24">
                <a-form-item class="mb-10" label="新密码" :colon="false">
                  <a-input-password v-model="new_password" placeholder="请输入新密码" />
                </a-form-item>
              </a-col>
              <a-col :span="24">
                <a-form-item class="mb-10" label="重复新密码" :colon="false">
                  <a-input-password v-model="confirm_password" placeholder="请重复输入新密码" />
                </a-form-item>
              </a-col>
            </a-row>
            <h5 class="font-semibold mt-20">密码修改建议</h5>
            <p>请遵从以下规则修改高强度密码:</p>
            <ul class="pl-15 text-muted">
              <li>至少1个特殊字符</li>
              <li>至少6位数</li>
              <li>至少包含1个大写字母</li>
              <li>经常更改密码</li>
            </ul>
            <a-row :gutter="[24]">
              <a-col :span="24" :lg="12">
              </a-col>
              <a-col :span="24" :lg="12" class="text-right">
                <a-button type="primary" @click="handleOk" class="px-25">确认修改</a-button>
              </a-col>
            </a-row>
          </a-form>
        </a-card>
        <!-- / Change Password card -->

        <!-- Delete Account card -->
        <a-card :bordered="false" id="delete-task" class="header-solid mb-24">
          <template #title>
            <h5 class="font-semibold">重置收益</h5>
            <p class="font-regular">重置收益数据后，5分钟内自动更新到网站！</p>
          </template>
          <a-form
              id="components-form-demo-normal-login"
              class="login-form list-settings-sessions"
              :hideRequiredMark="true"
          >
            <a-row type="flex" align="middle">
              <a-col :span="24" :md="12" class="ml-auto" style="display: flex; align-items: center; justify-content: flex-end">
                <a-button class="px-25" style="margin-right: 10px" @click="del_task(0)">
                  删除全部数据
                </a-button>
                <a-button type="primary" class="px-25" @click="del_task(1)">
                  删除亏损数据
                </a-button>
              </a-col>
            </a-row>
          </a-form>
        </a-card>
        <!-- / Delete Account card -->

        <!-- Delete Account card -->
        <a-card :bordered="false" id="delete-account" class="header-solid mb-24">
          <template #title>
            <h5 class="font-semibold">删除账号</h5>
            <p class="font-regular">一旦删除将无法恢复，请确认后操作！</p>
          </template>
          <a-form
              id="components-form-demo-normal-login"
              class="login-form list-settings-sessions"
              :hideRequiredMark="true"
          >
            <a-row type="flex" align="middle">
              <a-col style="min-width: 40px;" class="text-center">
                <a-switch disabled />
              </a-col>
              <a-col class="pl-15">
                <p class="mb-0 font-semibold">确认</p>
                <small class="text-dark">我想删除我的账号</small>
              </a-col>
              <a-col :span="24" :md="12" class="ml-auto" style="display: flex; align-items: center; justify-content: flex-end">
<!--                <a-button>-->
<!--                  DEACTIVATE-->
<!--                </a-button>-->
                <a-button type="danger" class="px-25">
                  删除账号
                </a-button>
              </a-col>
            </a-row>
          </a-form>
        </a-card>
        <!-- / Delete Account card -->

      </a-col>

    </a-row>
  </div>
</template>

<script>
import http from "../utils/http"

export default {
  name: "Settings",
  data() {
    return {
      password: '',
      new_password: '',
      confirm_password: '',

      showLess_wechat: false,
      wechat: false,
      wechat_code: '',

      showLess_telegram_bot: false,
      telegram_bot: false,

      showLess_qq_mail: false,
      qq_mail: false,
      qq: '',
      qq_password: '',
      // 提交状态
      isLoading: false,
    }
  },
  methods: {
    async del_task(type) {
      this.isLoading = true;
      const response = await http.post('/api/del_task/?token=' + localStorage.getItem("token"), {
        type: type,
      });
      if (response.data.code === 0) {
        this.$message.success(response.data.msg);
      } else {
        this.$message.error(response.data.msg || "重置失败，请稍后再试");
      }
     this.isLoading = false;
    },

    handleOk(e) {
      this.changePassword(this.password, this.new_password, this.confirm_password).then(response => {
        console.log(response);
        if (response.data.code === 0) {
          this.$message.success('修改成功');
          this.visible = false;
        } else {
          this.$message.error(response.data.error || "密码长度不能小于6位");
        }
      })
    },
    changePassword(password, new_password, confirm_password) {
      return http.post("/api/changepassword" + "/?token=" + localStorage.getItem("token"), {
        password: password,
        new_password: new_password,
        confirm_password: confirm_password,
      })
    },
    toggleShowLess(e) {
      if (e === 'wechat') {
        if (this.wechat) {
          this.showLess_wechat = !this.showLess_wechat;
        } else {
          this.$message.error('请先打开微信服务号开关！');
        }
      } else if (e === 'telegram_bot') {
        if (this.telegram_bot) {
          this.showLess_telegram_bot = !this.showLess_telegram_bot;
        } else {
          this.$message.error('请先打开Telegram机器人开关！');
        }
      } else if (e === 'qq_mail') {
        if (this.qq_mail) {
          this.showLess_qq_mail = !this.showLess_qq_mail;
        } else {
          this.$message.error('请先打开QQ邮箱开关！');
        }
      }
    },
    async onChange(e) {
      if (e === 'wechat') {
        const response = await http.patch('/api/wx/?token=' + localStorage.getItem("token"),{"wx": this.wechat});
        if (this.wechat === false) {
          this.showLess_wechat = false;
        }
        if (response.data.code !== 0) {
          this.wechat = !this.wechat;
          this.$message.error(response.data.msg);
        }
      } else if (e === 'qq_mail') {
        const response = await http.patch('/api/qqmail/?token=' + localStorage.getItem("token"),{"qq_mail": this.qq_mail,});
        if (this.qq_mail === false) {
          this.showLess_qq_mail = false;
        }
        if (response.data.code !== 0) {
          this.qq_mail = !this.qq_mail;
          this.$message.error(response.data.msg);
        }
      }
    },
    async generateWechatCode() {
      try {
        const response = await http.post('/api/wx/?token=' + localStorage.getItem("token"));
        if (response.data.code === 0) {
          this.wechat_code = response.data.wx_code;
        }
      } catch (error) {
        console.error('获取用户数据失败:', error);
      }
    },
    async submitQQmail() {
      this.isLoading = true;
      const key = 'updatable';
      this.$message.loading({ content: '邮箱校验中，请稍后...', key, duration: 0 });
      try {
        const response = await http.post('/api/qqmail/?token=' + localStorage.getItem("token"), {
          qq: this.qq,
          password: this.qq_password,
        });
        if (response.data.code !== 0) {
          this.$message.error({ content: response.data.error, key, duration: 2 });
        } else {
          this.$message.success({ content: '邮箱校验成功', key, duration: 2 });
        }
      } catch (error) {
        console.error('失败:', error);
      } finally {
        // 提交状态
        this.isLoading = false;
      }
    },
    async fetchWX() {
      try {
        const response = await http.get('/api/notify/?token=' + localStorage.getItem("token"));
        if (response.data.code === 0) {
          this.wechat = response.data.data.wx;
          this.wechat_code = response.data.data.wx_code;
          this.qq_mail = response.data.data.qq_mail;
          this.qq = response.data.data.qq;
          this.qq_password = response.data.data.password;
        } else {
          return;
        }
      } catch (error) {
        console.error('获取用户数据失败:', error);
      }
    }
  },
  created() {
    this.fetchWX();
  }
}
</script>

<style lang="scss" scoped>
#settings::v-deep {
  .ant-list {
    width: 100%;
  }
  .ant-list-item-meta-avatar {
    margin-right: 8px;
  }
  .ant-list-item-meta {
    align-items: center;
  }
  .ant-list-item-meta-title {
    margin: 0;
  }

  .ant-anchor-ink::before {
    display: none;
  }
  .ant-anchor-link {
    padding: 0;
    margin-top: 8px;
  }
  .ant-anchor-link a {
    width: 100%;
    border-radius: 8px;
    color: #67748e !important;
    padding: 10px 16px;
    background-color: transparent;
    transition: background-color 0.3s ease-in;
  }
  .ant-anchor-link a:hover {
    background-color: #eeeeee;
  }
  .ant-anchor-link a svg g {
    fill: #344767;
  }
  .ant-anchor-link a svg {
    margin-right: 8px;
  }

  .card-profile-head {
    margin: 0 0 24px;
  }
  .tags-field .ant-form-item-control {
    line-height: 33px;
  }
  .form-tag.ant-tag {
    border-radius: 20px;
    padding: 4px 10px;
    font-size: 12px;
    font-weight: 500;
    margin-right: 3.75px;
    margin-bottom: 3.75px;
    background-color: #3a416f;
    border: 1px solid #3a416f;
    color: #fff;
  }
  .form-tag.ant-tag .anticon-close {
    color: #fff;
    height: 16px;
    border-left: 1px solid hsla(0,0%,100%,.3);
    padding-left: 5px;
    padding-top: 2px;
    opacity: .75;
  }
  .form-tag.ant-tag .anticon-close:hover {
    color: #fff;
    opacity: 1;
  }
  .tags-field .ant-input {
    margin-bottom: 5px;
    margin-top: 4px;
  }

  .tags-field .ant-select {
    .ant-select-selection__choice__remove i {
      color: #fff;
      height: 16px;
      border-left: 1px solid hsla(0,0%,100%,.3);
      padding-left: 5px;
      padding-top: 2px;
      opacity: .75;

      &:hover {
        color: #fff;
        opacity: 1;
      }
    }
    .ant-select-selection__rendered > ul > li:not(.ant-select-search) {
      border-radius: 20px;
      padding: 2px 27px 2px 10px;
      font-size: 12px;
      font-weight: 500;
      margin-right: 3.75px;
      margin-bottom: 3.75px;
      background-color: #3a416f;
      border: 1px solid #3a416f;
      color: #fff;
      line-height: 2;
      height: 30px;
    }
    .ant-select-selection--multiple {
      padding: 8px 10px;
    }
  }

  hr.gradient-line {
    margin: 18px 0;
    border: none;
    height: 1px;
    background-color: #f5f5f5;
    background-image: linear-gradient(90deg, transparent, rgba(0, 0, 0, .4), transparent);
    opacity: .25;
  }
}

</style>